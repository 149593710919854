<template>
  <a-card title='Admin settings' :bordered='false' style='width: 100%' :headStyle='{borderBottom: 0}' :bodyStyle='{paddingTop: 0}'>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.bookingReference.name.$dirty && valid.bookingReference.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="valid.bookingReference.name.$dirty && valid.bookingReference.name.$invalid"
            >
              Onjuiste invoer
            </span>
        </template>
        <a-input
          allow-clear
          id="recipientAddress"
          v-model="dashboardMain.mkThree.bookingReference.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusProps', 'bookingReference', true, 'mkThree')"
          @blur="$emit('focusProps', 'bookingReference', false, 'mkThree')"
        />
      </a-form-item>

      <label for="recipientAddress"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.bookingReference.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.bookingReference.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.userSearch.name.$dirty && valid.userSearch.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="valid.userSearch.name.$dirty && valid.userSearch.name.$invalid"
            >
              Onjuiste invoer
            </span>
        </template>
        <a-auto-complete
          style="width: 100%; background: transparent"
          size="large"
          id="userSearch"
          v-model="dashboardMain.mkThree.userSearch.name"
          :filter-option="filterOption"
          @focus="$emit('focusProps', 'userSearch', true, 'mkThree')"
          @blur="$emit('focusProps', 'userSearch', false, 'mkThree')"
          optionLabelProp="title"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="(item, idx) in dashboardMain.mkThree.userSearch.dataSource"
              :key="item.id"
              :value="`${item.id}`"
              :title="`${ item.name }; ${item.email}`"
            >
              <div style="display: flex; justify-content: space-between; align-items: center">
                <p style="margin: 0">
                  {{ item.name }}; {{item.email}}
                </p>
              </div>
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-item>

      <label for="recipientAddress"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.userSearch.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.userSearch.dictionary }}
      </label>
    </div>
  </a-card>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "SuperAdmin",
  props: {
    valid: {
      type: Object,
      default: {}
    }
  },
  created() {
    this.getUsersList()
  },
  methods: {
    ...mapActions([
      'getUsersList'
    ]),
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      );
    },
  },
  computed: {
    ...mapState({
      dashboardMain: state => state.dashboardMain
    }),
  },
}
</script>

<style scoped>

</style>
