import {helpers} from "vuelidate/lib/validators";

export const isDecimalWithDotOrComma = helpers.regex('isDecimalWithDotOrComma', /^\d+([.,]\d+)?$/)
export const customMaxValue = (param) => (value) => {
  if (param && value) {
    let tt = Number((value + '').replace(/,/g, '.'))
    return tt < param
  }
  return false
}
