<template>
  <a-card title='Reference settings' :bordered='false' style='width: 100%' :headStyle='{borderBottom: 0}' :bodyStyle='{paddingTop: 0}'>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.remarks.name.$dirty && valid.remarks.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="valid.remarks.name.$dirty && valid.remarks.name.$invalid"
            >
              Onjuiste invoer
            </span>
        </template>
        <a-input
          allow-clear
          id="remarks"
          v-model="dashboardMain.mkThree.remarks.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusProps', 'remarks', true, 'mkThree')"
          @blur="$emit('focusProps', 'remarks', false, 'mkThree')"
        />
      </a-form-item>

      <label for="remarks"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.remarks.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.remarks.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.personalRef.name.$dirty && valid.personalRef.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="valid.personalRef.name.$dirty && valid.personalRef.name.$invalid"
            >
              {{ dashboardMain.mkThree.personalRef.dictionary }} onjuist
            </span>
        </template>
        <a-input
          allow-clear
          id="personalRef"
          v-model="dashboardMain.mkThree.personalRef.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusProps', 'personalRef', true, 'mkThree')"
          @blur="$emit('focusProps', 'personalRef', false, 'mkThree')"
        />
      </a-form-item>

      <label for="personalRef"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.personalRef.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.personalRef.dictionary }}
      </label>
    </div>
  </a-card>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ReferenceRemarks",
  props: {
    valid: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapState({
      dashboardMain: state => state.dashboardMain
    }),
  },
}
</script>

