<template>
  <a-modal
    v-model="visible"
    title="Tijdelijke aanpassing luchtvracht tarief Shanghai!"
    :closable="false"
    :maskClosable="false"
    :keyboard="false"
    @ok="hideModal"
    :footer="null"
  >
    <p>
      De tarieven voor luchtvracht Shanghai zijn momenteel hoger dan normaal. Dit door achterstanden en problemen in de
      luchthaven van Shanghai. De huidige prijs indicatie is €6,30 per kilo. LET OP! Dit is een indicatie en kan dus
      uiteindelijk iets hoger of lager uitvallen. Voor vragen of meer info kan je terecht bij onze live chat.
    </p>
    <a-button
      type="primary"
      @click="hideModal"
    >
      Oké
    </a-button>

  </a-modal>
</template>

<script>
export default {
  name: "NotificationModal",
  props: ['handler'],
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    hideModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
