<template>
  <div>
    <a-collapse
      expand-icon-position="right"
      v-model="activePanel"
      style="background-color: transparent !important"
      v-show="!dashboardMain.isLoading"
    >
      <a-collapse-panel
        key="1"
        header="Gegevens leverancier"
        class="antdCollapse"
        style="background-color: white"
      >
        <a-button
          icon="edit"
          slot="extra"
          type="link"
          size="small"
          class="editStage"
          @click.stop="changeStateForTheNextStage({to: 1})"
        />
        <div class="collapsePanel"
             v-for="(field, key) in dashboardMain.mkOne"
             :key="key"
             v-if="field.dictionary && key !== 'transportType' && key !== 'warhouseId' && key !== 'isDangerousMkOne' && key !== 'incotermsRule' && key !== 'customWarehouseAddress'"
        >
          <span class="collapsePanel__text">
            {{ field.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{ field.name || '-' }}
          </span>
        </div>
        <div class="collapsePanel">
          <span class="collapsePanel__text">
            {{ dashboardMain.mkOne.transportType.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{
              dashboardMain.mkOne.transportType.data.filter(obj => dashboardMain.mkOne.transportType.name === obj.id)[0].name || '-'
            }}
          </span>
        </div>
        <div class="collapsePanel">
          <span class="collapsePanel__text">
            {{ dashboardMain.mkOne.incotermsRule.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{ incotermsRuleName }}
          </span>
        </div>
        <div class="collapsePanel">
          <span class="collapsePanel__text">
            {{ dashboardMain.mkOne.warhouseId.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{ shipmentAddress }}
          </span>
        </div>
        <div class="collapsePanel">
          <span class="collapsePanel__text">
            {{ dashboardMain.mkThree.personalRef.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{ dashboardMain.mkThree.personalRef.name || '-' }}
          </span>
        </div>
        <div class="collapsePanel">
          <span class="collapsePanel__text">
            {{ dashboardMain.mkOne.isDangerousMkOne.dictionary }}
          </span>
          <span class="collapsePanel__text">
            {{ (dashboardMain.mkOne.isDangerousMkOne.name === 1 ? 'Ja ' : 'Nee') || '-' }}
          </span>
        </div>
      </a-collapse-panel>
      <a-collapse-panel
        key="2"
        header="Product(en)"
        class="antdCollapse"
        style="background-color: white"
      >
        <a-button
          icon="edit"
          slot="extra"
          type="link"
          size="small"
          class="editStage"
          @click.stop="changeStateForTheNextStage({to: 2})"
        />
        <div
          class="collapsePanel__product"
          v-for="(object, key) in dashboardMain.mkTwo.products"
          :key="object.key"
        >
          <h2>Product {{ key + 1 }}</h2>
          <div class="brd"
               v-for="(field, key) in object"
               v-if="field.dictionary && field.dictionary !== 'Total value in USD'"
               :key="key"
          >
            <template v-if="key === 'isDangerous'">
               <span class="collapsePanel__text">
                {{ field.dictionary }}
              </span>
              <span class="collapsePanel__text">
                {{ (field.name === 0 ? 'Nee' : 'Ja') || '-' }}
              </span>
            </template>
            <template v-else>
              <span class="collapsePanel__text">
                {{ field.dictionary }}
              </span>
              <span class="collapsePanel__text">
                {{ field.name || '-' }}
              </span>
            </template>

          </div>

          <div class="brd">
            <h3 class="collapsePanel__text">
              Sub Products
            </h3>
            <h3 class="collapsePanel__text">
              {{ object.subProductObj.product_subs.length || '-' }}
            </h3>
          </div>

          <div class="brd">
            <h3 class="collapsePanel__text" style="font-weight: 500">
              {{ object.totalValue.dictionary }}
            </h3>
            <h3 class="collapsePanel__text">
              ${{ object.totalValue.name || '-' }}
            </h3>
          </div>
        </div>
        <div class="totalForAllProducts">
          <span>
            Totale waarde alle producten
          </span>
          <span>
            ${{ totalProducts }}
          </span>
        </div>
      </a-collapse-panel>
      <a-collapse-panel
        key="3"
        header="Levering"
        class="antdCollapse"
        style="background-color: white"
      >
        <a-button
          icon="edit"
          slot="extra"
          type="link"
          size="small"
          class="editStage"
          @click.stop="changeStateForTheNextStage({to: 3})"
        />
        <div class="collapsePanel"
        >
          <span class="collapsePanel__text">
            {{ dashboardMain.mkThree.lvb.dictionary || '-' }}
          </span>
          <span class="collapsePanel__text">
             {{ lvbFilter }}
          </span>
        </div>
        <template v-if="dashboardMain.mkThree.lvb.name">
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Stad
            </span>
            <span class="collapsePanel__text">
              {{ lvbNumericFilter.city || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Huisnummer
            </span>
            <span class="collapsePanel__text">
              {{ lvbNumericFilter.house_number || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Land
            </span>
            <span class="collapsePanel__text">
              {{ lvbNumericFilter.country || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Toevoeging
            </span>
            <span class="collapsePanel__text">
              {{ lvbNumericFilter.addition || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Postcode
            </span>
            <span class="collapsePanel__text">
              {{ lvbNumericFilter.postcode || '-' }}
            </span>
          </div>
        </template>
        <template v-if="!dashboardMain.mkThree.lvb.name">
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Huisnummer
            </span>
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.houseNumber.name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Stad
            </span>
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.city.name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Postcode
            </span>
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.postcode.name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Toevoeging
            </span>
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.addition.name || '-' }}
            </span>
          </div>
        </template>
        <template>
          <div 
            v-if="!isShipTransportType"
            class="collapsePanel"
          >
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.dhlInsured.dictionary || '-' }}
            </span>
            <span class="collapsePanel__text">
              {{ dhlFilter }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              {{ dashboardMain.mkThree.remarks.dictionary || '-' }}
            </span>
            <span class="collapsePanel__text bigText">
              {{ dashboardMain.mkThree.remarks.name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Land
            </span>
            <span class="collapsePanel__text">
              {{ countryIndex || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              {{ dashboardMain.mkOne.transportType.dictionary || '-' }}
            </span>
            <span class="collapsePanel__text">
              {{ transportTypeFilter || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Contactpersoon
            </span>
            <span class="collapsePanel__text">
              {{ rootState.userInfo.name || '-' }}
            </span>
          </div>
          <div class="collapsePanel">
            <span class="collapsePanel__text">
              Bedrijfsnaam
            </span>
            <span class="collapsePanel__text">
              {{ rootState.companyInfo.name || '-' }}
            </span>
          </div>
        </template>
      </a-collapse-panel>
    </a-collapse>
    <SuperAdmin
      @focusProps="(el, bool, step) => $emit('focusHandler', el, bool, step)"
      :valid="valid"
      v-if="rootState.userInfo.isAdmin"
    />
    <ReferenceRemarks
      @focusProps="(el, bool, step) => $emit('focusHandler', el, bool, step)"
      :valid="valid"
    />
    <div
      v-show="dashboardMain.errorText.length"
    >
      <a-alert
        style="margin-top: 10px"
        type="error"
        :message="dashboardMain.errorText"
        banner
      />
    </div>
    <div
      style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center"
      v-show="dashboardMain.isLoading"
    >
      <a-spin>
        <a-icon slot="indicator"
                type="loading"
                style="font-size: 42px"
                spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import SuperAdmin from "@/components/makeNewBooking/SuperAdmin";
import ReferenceRemarks from "@/components/makeNewBooking/ReferenceRemarks";


export default {
  name: "NewBookingFourthStep",
  data() {
    return {
      activePanel: ['1', '2', '3'],
      total: 0,
      checkedFields: [
        'remarks',
        'personalRef',
        'bookingReference',
        'userSearch',
      ],
    }
  },
  components: {
    SuperAdmin,
    ReferenceRemarks
  },
  props: {
    valid: {
      type: Object,
      default: {}
    }
  },
  beforeDestroy() {
    const clearedArr = this.checkedFields.reduce((accum, field) => {
      this.valid[field].$invalid && accum.push(field);
      return accum;
    }, []);
    this.clearAutofilledData({step: 'mkThree', fields: clearedArr});
  },
  methods: {
    ...mapMutations([
      'changeStateForTheNextStage',
      'clearAutofilledData',
    ]),
  },
  computed: {
    ...mapState({
      rootState: state => state,
      dashboardMain: state => state.dashboardMain
    }),
    totalProducts() {
      return this.dashboardMain.mkTwo.products.reduce((acc, product) => {
        return acc + +product.totalValue.name.replace(',', '.')
      }, 0)
    },
    lvbFilter() {
      if (this.dashboardMain.mkThree.lvb.name === null) return '-'
      else if (this.dashboardMain.mkThree.lvb.name) return 'Ja'
      else return 'Nee'
    },
    dhlFilter() {
      if (this.dashboardMain.mkThree.dhlInsuredFloat.name === null) return '-'
      else if (this.dashboardMain.mkThree.dhlInsured.name) return this.dashboardMain.mkThree.dhlInsuredFloat.name
      else return 'No'
    },
    countryIndex() {
      if (this.dashboardMain.mkThree.countryIndex.name === 1) return 'Nederland'
      return 'België'
    },
    transportTypeFilter() {
      let transportType = this.dashboardMain.mkOne.transportType
      return transportType.data.filter(type => type.id === transportType.name)[0].name
    },
    lvbNumericFilter() {
      return JSON.parse(this.dashboardMain.mkThree.lvbNumeric.name)
    },
    isShipTransportType () {
      return this.dashboardMain.mkOne.transportType.name === 2;
    },
    incotermsRuleName () {
      const rule = this.dashboardMain.mkOne.incotermsRule.name;
      if (rule === 1) return 'EXW';
      if (rule === 2) return 'FCA';
      if (rule === 3) return 'FOB';
    },
    shipmentAddress () {
      let address;
      switch (this.dashboardMain.mkOne.warhouseId.name) {
        case 0:
          address = this.dashboardMain.mkOne.customWarehouseAddress.name; 
          break;

        case 1:
          address = 'Shenzhen'; 
          break;

        case 2:
          address = 'Shanghai'; 
          break;

        case 3:
          address = 'Ningbo'; 
          break;
      
        default:
          address = '-';
          break;
      }
      return address;
    },
  }
}
</script>

<style scoped lang="scss">
.collapsePanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(245, 244, 244);
  padding: 16px 0;

  &:last-child {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.bigText {
  max-width: 300px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.collapsePanel__product {
  &:not(:first-child) {
    margin-top: 20px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid rgb(245, 244, 244);

    &:last-child {
      padding-top: 16px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.totalForAllProducts {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  color: white;
  background-color: #FFA300;
  margin: 25px -16px -16px;

  span {
    font-weight: bold;
    font-size: 18px;
  }
}

.antdCollapse {
  margin-bottom: 25px !important;
}

.editStage {
  width: 24px !important;
  height: 20px !important;
}

</style>
