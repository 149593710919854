<template>
  <div>
    <a-collapse expand-icon-position="right">
      <a-collapse-panel key="1" :header="`Product  #${idx + 1}`">
        <a-button icon="delete"
                  slot="extra"
                  type="link"
                  size="small"
                  class="deleteProduct"
                  :disabled="state.isDeleteBtnDisabled"
                  @click.stop="deleteProduct(idx)"
        />
        <div>
          <div class="formWrapper__form--inputWrapper">
            <a-form-item
              :validate-status="(validators.products.$each[idx].description.name.$dirty && validators.products.$each[idx].description.name.$invalid) ? 'error' : 'success'"
            >
              <template #help>
                <span
                  v-show="validators.products.$each[idx].description.name.$dirty && validators.products.$each[idx].description.name.$invalid"
                >
                  {{ state.products[idx].description.dictionary }} onjuist
                </span>
              </template>
              <a-textarea
                allow-clear
                :auto-size="{ minRows: 3, maxRows: 5 }"
                id="description"
                v-model="state.products[idx].description.name"
                size="large"
                class="formWrapper__form--input"
                @focus="$emit('focusProps', 'description', true, 'mkTwo', true, 'products', idx)"
                @blur="$emit('focusProps', 'description', false, 'mkTwo', true, 'products', idx)"
              />
            </a-form-item>

            <label for="description"
                   class="formWrapper__form--label"
                   :class="state.products[idx].description.isFocused ? 'focused' : ''"
            >
              {{ state.products[idx].description.dictionary }}
            </label>
          </div>

          <div class="sub-product">
            <slot></slot>
          </div>

          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].hsCode.name.$dirty && validators.products.$each[idx].hsCode.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].hsCode.name.$dirty && validators.products.$each[idx].hsCode.name.$invalid"
                  >
                    {{ state.products[idx].hsCode.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="hsCode"
                  v-model="state.products[idx].hsCode.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'hsCode', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'hsCode', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>
              <label for="hsCode"
                     class="formWrapper__form--label"
                     :class="state.products[idx].hsCode.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].hsCode.dictionary }}
              </label>
            </div>
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].enaCode.name.$dirty && validators.products.$each[idx].enaCode.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].enaCode.name.$dirty && validators.products.$each[idx].enaCode.name.$invalid"
                  >
                    {{ state.products[idx].enaCode.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="enaCode"
                  v-model="state.products[idx].enaCode.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'enaCode', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'enaCode', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="enaCode"
                     class="formWrapper__form--label"
                     :class="state.products[idx].enaCode.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].enaCode.dictionary }}
              </label>
            </div>
          </div>
          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].pricePerPiece.name.$dirty && validators.products.$each[idx].pricePerPiece.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].pricePerPiece.name.$dirty && validators.products.$each[idx].pricePerPiece.name.$invalid"
                  >
                    {{ state.products[idx].pricePerPiece.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="pricePerPiece"
                  v-model="state.products[idx].pricePerPiece.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'pricePerPiece', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'pricePerPiece', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="pricePerPiece"
                     class="formWrapper__form--label"
                     :class="state.products[idx].pricePerPiece.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].pricePerPiece.dictionary }}
              </label>
            </div>
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].otherCosts.name.$dirty && validators.products.$each[idx].otherCosts.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].otherCosts.name.$dirty && validators.products.$each[idx].otherCosts.name.$invalid"
                  >
                    {{ state.products[idx].otherCosts.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="otherCosts"
                  v-model="state.products[idx].otherCosts.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'otherCosts', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'otherCosts', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="otherCosts"
                     class="formWrapper__form--label"
                     :class="state.products[idx].otherCosts.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].otherCosts.dictionary }}
              </label>
            </div>
          </div>
          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].totalValue.name.$dirty && validators.products.$each[idx].totalValue.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].totalValue.name.$dirty && validators.products.$each[idx].totalValue.name.$invalid"
                  >
                    {{ state.products[idx].totalValue.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="totalValue"
                  v-model="state.products[idx].totalValue.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'totalValue', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'totalValue', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="pricePerPiece"
                     class="formWrapper__form--label"
                     :class="state.products[idx].totalValue.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].totalValue.dictionary }}
              </label>
            </div>
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].quantityInBooking.name.$dirty && validators.products.$each[idx].quantityInBooking.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
            <span
              v-show="validators.products.$each[idx].quantityInBooking.name.$dirty && validators.products.$each[idx].quantityInBooking.name.$invalid"
            >
              {{ state.products[idx].quantityInBooking.dictionary }} onjuist
            </span>
                </template>
                <a-input
                  allow-clear
                  id="quantityInBooking"
                  v-model="state.products[idx].quantityInBooking.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'quantityInBooking', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'quantityInBooking', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="quantityInBooking"
                     class="formWrapper__form--label"
                     :class="state.products[idx].quantityInBooking.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].quantityInBooking.dictionary }}
              </label>
            </div>
          </div>
          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
            <a-tooltip>  
              <template
                v-if="hasSubProduct"
                slot="title"
              >
                Zodra jij een product variant toevoegt, dien jij het aantal dozen per product variant aan te geven. 
                Het totaal van alle dozen van jouw product varianten wordt automatisch weergegeven bij het kopje 'Hoeveelheid dozen'. 
                Mocht je perongeluk op een product variant geklikt hebben, kan jij deze weer verwijderen door op het prullenbakje te klikken.
              </template>
              <a-form-item
                :validate-status="(validators.products.$each[idx].quantityBoxes.name.$dirty && validators.products.$each[idx].quantityBoxes.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
            <span
              v-show="validators.products.$each[idx].quantityBoxes.name.$dirty && validators.products.$each[idx].quantityBoxes.name.$invalid"
            >
              {{ state.products[idx].quantityBoxes.dictionary }} onjuist
            </span>
                </template>
                <a-input
                  allow-clear
                  id="quantityBoxes"
                  v-model="state.products[idx].quantityBoxes.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'quantityBoxes', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'quantityBoxes', false, 'mkTwo', true, 'products', idx)"
                  :disabled="hasSubProduct"
                  @change="changeProductCountHandler(idx, 'countBoxesToLeaveInWarehouse')"
                >
                </a-input>

              </a-form-item>

              <label for="quantityBoxes"
                     class="formWrapper__form--label"
                     :class="state.products[idx].quantityBoxes.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].quantityBoxes.dictionary }}
                <a-icon 
                  v-if="hasSubProduct" 
                  type="info-circle" 
                  style="color: black"
                />
              </label>
              </a-tooltip>
            </div>
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].grossWeight.name.$dirty && validators.products.$each[idx].grossWeight.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].grossWeight.name.$dirty && validators.products.$each[idx].grossWeight.name.$invalid"
                  >
                    {{ state.products[idx].grossWeight.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="grossWeight"
                  v-model="state.products[idx].grossWeight.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'grossWeight', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'grossWeight', false, 'mkTwo', true, 'products', idx)"
                >
                </a-input>

              </a-form-item>

              <label for="grossWeight"
                     class="formWrapper__form--label"
                     :class="state.products[idx].grossWeight.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].grossWeight.dictionary }}
              </label>
            </div>
          </div>
          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
              <a-tooltip>
                <template v-if="state.products[idx].cbm.name && state.products[idx].cbm.name < 1" slot="title">
                  U wordt voor minimaal 1 CBM (m³) belast
                </template>
                <a-form-item
                  :validate-status="(validators.products.$each[idx].cbm.name.$dirty && validators.products.$each[idx].cbm.name.$invalid) ? 'error' : 'success'"
                >
                  <template #help>
                    <span
                      v-show="validators.products.$each[idx].cbm.name.$dirty && validators.products.$each[idx].cbm.name.$invalid"
                    >
                      {{ state.products[idx].cbm.dictionary }} onjuist
                    </span>
                  </template>
                  <a-input
                    allow-clear
                    id="cbm"
                    v-model="state.products[idx].cbm.name"
                    size="large"
                    class="formWrapper__form--input"
                    @focus="$emit('focusProps', 'cbm', true, 'mkTwo', true, 'products', idx)"
                    @blur="$emit('focusProps', 'cbm', false, 'mkTwo', true, 'products', idx)"
                  >
                  </a-input>
                </a-form-item>
                <label for="quantityBoxes"
                      class="formWrapper__form--label"
                      :class="state.products[idx].cbm.isFocused ? 'focused' : ''"
                >
                  {{ state.products[idx].cbm.dictionary }}
                  <a-icon v-if="state.products[idx].cbm.name && state.products[idx].cbm.name < 1" type="question-circle" />
                </label>
              </a-tooltip>
            </div>
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].isDangerous.name.$dirty && validators.products.$each[idx].isDangerous.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].isDangerous.name.$dirty && validators.products.$each[idx].isDangerous.name.$invalid"
                  >
                    {{ state.products[idx].isDangerous.dictionary }} onjuist
                  </span>
                </template>
                <a-select size="large"
                          style="width: 100%; background: transparent"
                          id="isDangerous"
                          v-model="state.products[idx].isDangerous.name"
                          @focus="$emit('focusProps', 'isDangerous', true, 'mkTwo', true, 'products', idx)"
                          @blur="$emit('focusProps', 'isDangerous', false, 'mkTwo', true, 'products', idx)"
                >
                  <a-select-option :value="1">
                    Ja
                  </a-select-option>
                  <a-select-option :value="0">
                    Nee
                  </a-select-option>
                </a-select>
              </a-form-item>
              <label for="isDangerous"
                     class="formWrapper__form--label"
                     :class="state.products[idx].isDangerous.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].isDangerous.dictionary }}
              </label>
            </div>
          </div>
          <div class="form__inlineInputs" v-if="mkOne.transportType.name === 2">
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].leaveInWarehouse.name.$dirty && validators.products.$each[idx].leaveInWarehouse.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].leaveInWarehouse.name.$dirty && validators.products.$each[idx].leaveInWarehouse.name.$invalid"
                  >
                    {{ state.products[idx].leaveInWarehouse.dictionary }} onjuist
                  </span>
                </template>
                <a-select
                  size="large"
                  style="background: transparent; width: 100%"
                  id="leaveInWarehouse"
                  v-model="state.products[idx].leaveInWarehouse.name"
                  @focus="$emit('focusProps', 'leaveInWarehouse', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'leaveInWarehouse', false, 'mkTwo', true, 'products', idx)"
                  :disabled="hasSubProduct"
                  @change="changeProductCountHandler(idx, 'countBoxesToLeaveInWarehouse')"
                >
                  <a-select-option :value="2">Ja, alles</a-select-option>
                  <a-select-option :value="1">Ja, een deel</a-select-option>
                  <a-select-option :value="0">Nee</a-select-option>
                </a-select>

              </a-form-item>

              <label for="leaveInWarehouse"
                     class="formWrapper__form--label"
                     :class="state.products[idx].leaveInWarehouse.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].leaveInWarehouse.dictionary }}
              </label>
            </div>
            <div v-if="state.products[idx].leaveInWarehouse.name" class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="(validators.products.$each[idx].countBoxesToLeaveInWarehouse.name.$dirty && validators.products.$each[idx].countBoxesToLeaveInWarehouse.name.$invalid) ? 'error' : 'success'"
              >
                <template #help>
                  <span
                    v-show="validators.products.$each[idx].countBoxesToLeaveInWarehouse.name.$dirty && validators.products.$each[idx].countBoxesToLeaveInWarehouse.name.$invalid"
                  >
                    {{ state.products[idx].countBoxesToLeaveInWarehouse.dictionary }} onjuist
                  </span>
                </template>
                <a-input
                  allow-clear
                  id="countBoxesToLeaveInWarehouse"
                  v-model="state.products[idx].countBoxesToLeaveInWarehouse.name"
                  size="large"
                  class="formWrapper__form--input"
                  @focus="$emit('focusProps', 'countBoxesToLeaveInWarehouse', true, 'mkTwo', true, 'products', idx)"
                  @blur="$emit('focusProps', 'countBoxesToLeaveInWarehouse', false, 'mkTwo', true, 'products', idx)"
                  :disabled="hasSubProduct || state.products[idx].leaveInWarehouse.name === 2"
                >
                </a-input>

              </a-form-item>

              <label for="countBoxesToLeaveInWarehouse"
                     class="formWrapper__form--label"
                     :class="state.products[idx].countBoxesToLeaveInWarehouse.isFocused ? 'focused' : ''"
              >
                {{ state.products[idx].countBoxesToLeaveInWarehouse.dictionary }}
              </label>
            </div>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "Product",
  props: {
    product: Object,
    idx: Number,
    validators: Object,
  },
  computed: {
    ...mapState({
      state: state => state.dashboardMain.mkTwo,
      mkOne: state => state.dashboardMain.mkOne
    }),
    subProductList () {
      return this.product.subProductObj.product_subs;
    },
    hasSubProduct () {
      return !!this.subProductList.length;
    },
    totalSubProductsValue () {
      return this.subProductList.reduce((accum, item) => {
        return accum + +item.quantityBoxes.name;
      }, 0) 
    },
    totalSubProductsInWarehouse () {
      return this.subProductList.reduce((accum, item) => {
        return accum + +item.quantityInBooking.name;
      }, 0) 
    },
    subProdcutsCount () {
      return this.product.subProductObj.product_subs.length;
    },
    areAllSubProdsFilledIn () {
      return this.hasSubProduct && this.subProductList.every(({leaveInWarehouse: {name}}) => name !== null);
    },
    noOneSubProductSendToWarehouse () {
      return this.areAllSubProdsFilledIn && this.subProductList.every(({leaveInWarehouse: {name}}) => name === 0);
    },
    areAllSubProductsSendToWarehouse () {
      return this.areAllSubProdsFilledIn && this.subProductList.every(({leaveInWarehouse: {name}}) => name === 2);
    },
    subProductsHaveDifferentStatuses () {
      return this.areAllSubProdsFilledIn && !this.noOneSubProductSendToWarehouse && !this.areAllSubProductsSendToWarehouse;
    },
    sendAllProductCountToWarehouse () {
      return this.product.leaveInWarehouse.name;
    }
  },
  watch: {
    totalSubProductsValue (total) {
      if (this.hasSubProduct && total > 0) {
        const key = this.product.key;
        this.totalProductHandler({ total, key, fieldName: 'quantityBoxes' })
      }
    },
    totalSubProductsInWarehouse (total) {
      if (this.hasSubProduct && total > 0) {
        const key = this.product.key;
        this.totalProductHandler({ total, key, fieldName: 'countBoxesToLeaveInWarehouse' })
      }
    },
    subProdcutsCount (newValue, oldValue) {
      const key = this.product.key;
      const fieldsName = ['leaveInWarehouse', 'countBoxesToLeaveInWarehouse', 'quantityBoxes'];
      oldValue === 0 && newValue === 1 && this.clearCustomFields({ key, fieldsName });
      newValue === 0 && oldValue === 1 && this.clearCustomFields({ key, fieldsName });
      this.validators.$reset();
    },
    subProductsHaveDifferentStatuses (bool) {
      this.changeProductStatus(bool, 1);
    },
    noOneSubProductSendToWarehouse (bool) {
      this.changeProductStatus(bool, 0);
    },
    areAllSubProductsSendToWarehouse (bool) {
      this.changeProductStatus(bool, 2);
    },
    sendAllProductCountToWarehouse (newValue, oldValue) {
      if (!this.hasSubProduct && newValue !== 2 && oldValue !== null) {
        const key = this.product.key;
        const fieldsName = ['countBoxesToLeaveInWarehouse'];
        this.clearCustomFields({ key, fieldsName });
        this.validators.$reset();
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteProduct',
      'totalProductHandler',
      'leaveProductInWarehouseHandler',
      'clearCustomFields'
    ]),
    changeProductCountHandler (idx, fieldName) {
      const addAllProductsToWarehouse = this.state.products[idx].leaveInWarehouse.name === 2;
      const quantityBoxes = this.state.products[idx].quantityBoxes.name;
      addAllProductsToWarehouse && quantityBoxes && this.totalProductHandler({ total: quantityBoxes, key: idx, fieldName });
    },
    changeProductStatus (bool, value) {
      bool && this.hasSubProduct && this.leaveProductInWarehouseHandler({ value: value, key: this.product.key })
    }
  }
}
</script>

<style scoped lang="scss">
.deleteProduct {
  width: 24px !important;
  height: 20px !important;
}
</style>
