<template>
  <div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="
          valid.providerName.name.$dirty && valid.providerName.name.$invalid
            ? 'error'
            : 'success'
        "
      >
        <template #help>
          <span
            v-show="
              valid.providerName.name.$dirty && valid.providerName.name.$invalid
            "
          >
            {{ dashboardMain.mkOne.providerName.dictionary }} onjuist
          </span>
        </template>
        <a-auto-complete
          allowClear
          style="width: 100%; background: transparent"
          size="large"
          id="providerName"
          v-model="dashboardMain.mkOne.providerName.name"
          :filter-option="filterOption"
          @focus="$emit('focusHandler', 'providerName', true, 'mkOne')"
          @dropdownVisibleChange="updateList"
          @blur="$emit('focusHandler', 'providerName', false, 'mkOne')"
          @select="selectHandler"
          optionLabelProp="value"
          ref="autoCompleteSelect"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in dashboardMain.mkOne.providerName.dataSource"
              :key="item.id"
              :value="item.name"
              :title="item.name"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <p style="margin: 0">
                  {{ item.name }}
                </p>
                <div>
                  <CustomIcon 
                    type="edit"
                    @click="editTemplateHandler(item.id)" 
                  />
                  <a-icon
                    type="close"
                    style="color: orange; margin-left: 15px"
                    @click.stop="deleteCompanyNameFromAutocomplete(item.id)"
                  />
                </div>
              </div>
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-item>

      <label
        for="providerName"
        class="formWrapper__form--label"
        :class="dashboardMain.mkOne.providerName.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkOne.providerName.dictionary }}
      </label>
    </div>

    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="
            valid.transportType.name.$dirty && valid.transportType.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.transportType.name.$dirty &&
                valid.transportType.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.transportType.dictionary }} onjuist
            </span>
          </template>
          <a-select
            size="large"
            style="width: 100%; background: transparent"
            id="transportType"
            @change="warehouseHandler"
            v-model="dashboardMain.mkOne.transportType.name"
            @focus="$emit('focusHandler', 'transportType', true, 'mkOne')"
            @blur="$emit('focusHandler', 'transportType', false, 'mkOne')"
          >
            <a-select-option
              v-for="option in dashboardMain.mkOne.transportType.data"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <label
          for="transportType"
          class="formWrapper__form--label"
          :class="dashboardMain.mkOne.transportType.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.transportType.dictionary }}
        </label>
      </div>

      <div   
        v-if="dashboardMain.mkOne.transportType.name"      
        class="formWrapper__form--inputWrapper"
      >
        <a-form-item
          :validate-status="
            valid.incotermsRule.name.$dirty && valid.incotermsRule.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.incotermsRule.name.$dirty &&
                valid.incotermsRule.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.incotermsRule.dictionary }} onjuist
            </span>
          </template>
          <a-select
            size="large"
            style="width: 100%; background: transparent"
            id="incotermsRule"
            @change="warehouseHandler"
            v-model="dashboardMain.mkOne.incotermsRule.name"
            @focus="$emit('focusHandler', 'incotermsRule', true, 'mkOne')"
            @blur="$emit('focusHandler', 'incotermsRule', false, 'mkOne')"
          >
            <template v-if="dashboardMain.mkOne.transportType.name">
              <a-select-option :value="1">EXW</a-select-option>
              <a-select-option :value="2">FCA</a-select-option>
            </template>
            <template 
                v-if="
                  dashboardMain.mkOne.transportType.name &&
                  dashboardMain.mkOne.transportType.name === 2
                ">
              <a-select-option :value="3">FOB</a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <label
          for="incotermsRule"
          class="formWrapper__form--label"
          :class="dashboardMain.mkOne.incotermsRule.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.incotermsRule.dictionary }}
        </label>
      </div>
    </div>      
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="
            valid.providerEmail.name.$dirty && valid.providerEmail.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.providerEmail.name.$dirty &&
                valid.providerEmail.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.providerEmail.dictionary }} onjuist
            </span>
          </template>
          <a-input-search
            allow-clear
            id="email"
            v-model="dashboardMain.mkOne.providerEmail.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'providerEmail', true, 'mkOne')"
            @blur="$emit('focusHandler', 'providerEmail', false, 'mkOne')"
          />
        </a-form-item>

        <label
          for="email"
          class="formWrapper__form--label"
          :class="dashboardMain.mkOne.providerEmail.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.providerEmail.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="
            valid.providerPhone.name.$dirty && valid.providerPhone.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.providerPhone.name.$dirty &&
                valid.providerPhone.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.providerPhone.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="providerPhone"
            v-model="dashboardMain.mkOne.providerPhone.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'providerPhone', true, 'mkOne')"
            @blur="$emit('focusHandler', 'providerPhone', false, 'mkOne')"
          >
          </a-input>
        </a-form-item>

        <label
          for="providerPhone"
          class="formWrapper__form--label"
          :class="dashboardMain.mkOne.providerPhone.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.providerPhone.dictionary }}
        </label>
      </div>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="
          valid.providerStreetHouseName.name.$dirty &&
          valid.providerStreetHouseName.name.$invalid
            ? 'error'
            : 'success'
        "
      >
        <template #help>
          <span
            v-show="
              valid.providerStreetHouseName.name.$dirty &&
              valid.providerStreetHouseName.name.$invalid
            "
          >
            {{ dashboardMain.mkOne.providerStreetHouseName.dictionary }} onjuist
          </span>
        </template>
        <a-textarea
          allow-clear
          :auto-size="{ minRows: 3, maxRows: 5 }"
          id="providerStreetHouseName"
          v-model="dashboardMain.mkOne.providerStreetHouseName.name"
          size="large"
          class="formWrapper__form--input"
          @focus="
            $emit('focusHandler', 'providerStreetHouseName', true, 'mkOne')
          "
          @blur="
            $emit('focusHandler', 'providerStreetHouseName', false, 'mkOne')
          "
        />
      </a-form-item>

      <label
        for="providerStreetHouseName"
        class="formWrapper__form--label"
        :class="
          dashboardMain.mkOne.providerStreetHouseName.isFocused ? 'focused' : ''
        "
      >
        {{ dashboardMain.mkOne.providerStreetHouseName.dictionary }}
      </label>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="
            valid.providerPostCode.name.$dirty &&
            valid.providerPostCode.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.providerPostCode.name.$dirty &&
                valid.providerPostCode.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.providerPostCode.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="providerPostCode"
            v-model="dashboardMain.mkOne.providerPostCode.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'providerPostCode', true, 'mkOne')"
            @blur="$emit('focusHandler', 'providerPostCode', false, 'mkOne')"
          />
        </a-form-item>

        <label
          for="providerPostCode"
          class="formWrapper__form--label"
          :class="
            dashboardMain.mkOne.providerPostCode.isFocused ? 'focused' : ''
          "
        >
          {{ dashboardMain.mkOne.providerPostCode.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="
            valid.recipientAddress.name.$dirty &&
            valid.recipientAddress.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.recipientAddress.name.$dirty &&
                valid.recipientAddress.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.recipientAddress.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="recipientAddress"
            v-model="dashboardMain.mkOne.recipientAddress.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'recipientAddress', true, 'mkOne')"
            @blur="$emit('focusHandler', 'recipientAddress', false, 'mkOne')"
          />
        </a-form-item>

        <label
          for="recipientAddress"
          class="formWrapper__form--label"
          :class="
            dashboardMain.mkOne.recipientAddress.isFocused ? 'focused' : ''
          "
        >
          {{ dashboardMain.mkOne.recipientAddress.dictionary }}
        </label>
      </div>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-tooltip>
        <template slot="title">
          Je kan je producten ook naar een andere (lucht)haven laten verzenden. Neem hiervoor alsjeblieft contact met ons op!
        </template>
        <a-form-item
          :validate-status="
            valid.warhouseId.name.$dirty && valid.warhouseId.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.warhouseId.name.$dirty && valid.warhouseId.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.warhouseId.dictionary }} onjuist
            </span>
          </template>
          <a-select
            size="large"
            style="width: 100%; background: transparent"
            id="warhouseId"
            v-model="dashboardMain.mkOne.warhouseId.name"
            @focus="$emit('focusHandler', 'warhouseId', true, 'mkOne')"
            @blur="$emit('focusHandler', 'warhouseId', false, 'mkOne')"
          >
            <template
              v-if="isDangerousProduct && airTransportType"
            >
              <a-select-option :value="1">Shenzhen</a-select-option>
            </template>
            <template v-else>
              <a-select-option :value="1">Shenzhen</a-select-option>
              <a-select-option 
                v-if="temporarilyHideShanghai" 
                :value="2"
              >
                Shanghai
              </a-select-option>
              <a-select-option 
                v-if="seaTransportType" 
                :value="3"
              >
                Ningbo
              </a-select-option>
              <a-select-option 
                v-if="seaTransportType" 
                :value="0"
              >
                Andere locatie
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <label
          for="warhouseId"
          class="formWrapper__form--label"
          :class="dashboardMain.mkOne.warhouseId.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.warhouseId.dictionary }}
          <a-icon type="question-circle"/>
        </label>
      </a-tooltip>
    </div>
    <div
      v-if="deliveryAddress === 0"
      class="formWrapper__form--inputWrapper"
    >
      <a-tooltip>
        <template slot="title">
          Wij zijn in staat om vanaf elke haven ter wereld een zeevracht voor jou te voorzien. Let op: Voor deze havens gelden niet onze vaste tarieven, wij zullen jou contact met jou opnemen voor een op maat offerte.
        </template>
        <a-form-item
          :validate-status="
            valid.customWarehouseAddress.name.$dirty &&
            valid.customWarehouseAddress.name.$invalid
              ? 'error'
              : 'success'
          "
        >
          <template #help>
            <span
              v-show="
                valid.customWarehouseAddress.name.$dirty &&
                valid.customWarehouseAddress.name.$invalid
              "
            >
              {{ dashboardMain.mkOne.customWarehouseAddress.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="customWarehouseAddress"
            v-model="dashboardMain.mkOne.customWarehouseAddress.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'customWarehouseAddress', true, 'mkOne')"
            @blur="$emit('focusHandler', 'customWarehouseAddress', false, 'mkOne')"
          />
        </a-form-item>
        <label
          for="customWarehouseAddress"
          class="formWrapper__form--label"
          :class="
            dashboardMain.mkOne.customWarehouseAddress.isFocused ? 'focused' : ''
          "
        >
          {{ dashboardMain.mkOne.customWarehouseAddress.dictionary }}
          <a-icon type="question-circle"/>
        </label>
     </a-tooltip>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-tooltip>
        <template slot="title">
          Gevaarlijke goederen zijn producten die bijvoorbeeld magneten,
          batterijen en vloeistoffen (ook verhard) bevatten. Vraag duidelijk aan
          jouw leverancier of dit het geval is. Indien het gevaarlijke goederen
          betreft, dan is het niet mogelijk om voor de aanleverlocatie Shanghai
          te kiezen
        </template>
        <a-form-item
          :validate-status="
          valid.isDangerousMkOne.name.$dirty &&
          valid.isDangerousMkOne.name.$invalid
            ? 'error'
            : 'success'
        "
        >
          <template #help>
          <span
            v-show="
              valid.isDangerousMkOne.name.$dirty &&
              valid.isDangerousMkOne.name.$invalid
            "
          >
            {{ dashboardMain.mkOne.isDangerousMkOne.dictionary }} onjuist
          </span>
          </template>
          <a-select
            size="large"
            style="width: 100%; background: transparent"
            id="isDangerousMkOne"
            v-model="dashboardMain.mkOne.isDangerousMkOne.name"
            @change="warehouseHandler"
            @focus="$emit('focusHandler', 'isDangerousMkOne', true, 'mkOne')"
            @blur="$emit('focusHandler', 'isDangerousMkOne', false, 'mkOne')"
          >
            <a-select-option :value="1"> Ja</a-select-option>
            <a-select-option :value="0"> Nee</a-select-option>
          </a-select>
        </a-form-item>
        <label
          for="isDangerousMkOne"
          class="formWrapper__form--label"
         
          :class="dashboardMain.mkOne.isDangerousMkOne.isFocused ? 'focused' : ''"
        >
          {{ dashboardMain.mkOne.isDangerousMkOne.dictionary }}
          <a-icon type="question-circle" />
        </label>
      </a-tooltip>
    </div>
    <div class="form__checkbox">
      <a-checkbox
        id="termsCheckbox"
        @change="checkboxHandler"
        :checked="dashboardMain.mkOne.saveProvider"
      >
        Gegevens opslaan
      </a-checkbox>
    </div>
    <div v-show="dashboardMain.mkOne.errorMessage">
      <a-alert
        style="margin-top: 10px"
        type="error"
        :message="dashboardMain.mkOne.errorMessage"
        banner
      />
    </div>
    <CustomModalWindow
      :visible="isOpenModal"
      okText="Verandering"
      centered
      title="Leveranciersgegevens bewerken"
      @cancel="closeModal"
      @ok="saveEditedTemplate"
      :loading="isChangingTemplateData"
      :closable="!isChangingTemplateData"
      :disabledCloseBtn="isChangingTemplateData"
      :disabledOkBtn="valid.templateData.$invalid"
    >
      <CustomInput
        v-for="(value, name) of templateData"
        :fieldData="value"
        :fieldName="name"
        :formFields="templateData"
        :validation="valid.templateData[name]"
        :key="name + value.id"
      />
    </CustomModalWindow>
    <CustomSpinner 
      v-if="isLoadingTemplate" 
      size="150" 
      centered
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import clearTemplateFields from "../../mixins/clearTemplateFields";
import editTemplates from "../../mixins/editTemplates";

export default {
  name: "NewBookingFirstStep",
  props: {
    valid: {
      type: Object,
      default: {},
    },
    secondStepValid: {
      type: Object,
      default: {},
    }
  },
  mixins: [clearTemplateFields, editTemplates],
  data() {
    return {
      templateFields: [
        "providerName",
        "providerStreetHouseName", 
        "recipientAddress", 
        "providerEmail", 
        "providerPhone", 
        "providerPostCode"
      ],
      outerField: 'mkOne',
      resetValidateField: 'providerName',
      parentModalStateField: 'isOpenModalAtFirstStep',
    }
  },
  watch: {
    transportType (newVal, oldVal) {
      if(newVal === 1 && oldVal === 2) {
        this.clearSubProdsWhileChangingTransportType();
        this.secondStepValid.$reset();
      }

      if (newVal && oldVal) {
        this.clearIncotermsRuleField();
        this.valid.incotermsRule.$reset();
      }
    },
    deliveryAddress (newVal, oldVal) {
      if (newVal && oldVal === 0) {
        this.clearCustomWarehouseAddress();
      }
    }
  },
  computed: {
    ...mapState({
      dashboardMain: (state) => state.dashboardMain,
      transportType: state => state.dashboardMain.mkOne.transportType.name,
      mainTemplateField: state => state.dashboardMain.mkOne.providerName.name,
      templateData: state => state.dashboardMain.mkOne.templateData,
      isChangingTemplateData: state => state.dashboardMain.isChangingTemplateData,
      isLoadingTemplate: state => state.dashboardMain.isLoadingTemplate,
    }),
    airTransportType () {
      return this.transportType === 1;
    },
    seaTransportType () {
      return this.transportType === 2;
    },
    isDangerousProduct () {
      return this.dashboardMain.mkOne.isDangerousMkOne.name;
    },
    deliveryAddress () {
      return this.dashboardMain.mkOne.warhouseId.name;
    },
    temporarilyHideShanghai() {
      return this.transportType === 2;
    },
  },
  created() {
    this.getTransportTypeList();
  },
  beforeDestroy() {
    this.clearErrorAndTempalteNotificationHandler();
  },
  methods: {
    ...mapMutations([
      "saveProviderHandler", 
      "clearWarehouseId", 
      "clearSubProdsWhileChangingTransportType", 
      "clearAutofilledData", 
      "clearErrorAndTempalteNotificationHandler",
      "clearIncotermsRuleField",
      "clearCustomWarehouseAddress",
      ]),
    ...mapActions([
      "deleteCompanyItem",
      "setCompanyFromList",
      "getCompaniesList",
      "getTransportTypeList",
      "editSupplierTemplateItem",
    ]),

    updateList(bool) {
      if (bool) {
        this.getCompaniesList();
      }
    },
    warehouseHandler(val) {
      if (val) this.clearWarehouseId();
    },
    checkboxHandler(e) {
      this.saveProviderHandler(e.target.checked);
    },
    getTemplatesItem (id, bool) {
      return this.setCompanyFromList({id, isToAutoFillData: bool})
    },
    editTemplateItem (id) {
      return this.editSupplierTemplateItem(id)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    deleteCompanyNameFromAutocomplete(title) {
      this.deleteCompanyItem(title)
        .then(() => this.getCompaniesList())
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .ant-input-lg{
    cursor: pointer;
  }
</style>
