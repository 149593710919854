<template>
  <div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.lvb.name.$dirty && valid.lvb.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.lvb.name.$dirty && valid.lvb.name.$invalid"
          >
           {{ dashboardMain.mkThree.lvb.dictionary }} onjuist
          </span>
        </template>
        <a-select
          size="large"
          style="width: 100%; background: transparent"
          id="lvb"
          v-model="dashboardMain.mkThree.lvb.name"
          @focus="$emit('focusHandler', 'lvb', true, 'mkThree')"
          @blur="$emit('focusHandler', 'lvb', false, 'mkThree')"
          @change="lvbChangeHandler"
          :disabled="isEveryProductSendToWarehouse"
        >
          <a-select-option :value="1">
            Ja
          </a-select-option>
          <a-select-option :value="0">
            Nee
          </a-select-option>
        </a-select>
      </a-form-item>
      <label for="lvb"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.lvb.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.lvb.dictionary }}
      </label>
    </div>
    <div 
      class="formWrapper__form--inputWrapper" 
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="
          valid.street.name.$dirty && valid.street.name.$invalid
            ? 'error'
            : 'success'
        "
      >
       <template #help>
          <span
            v-show="valid.street.name.$dirty && valid.street.name.$invalid"
          >
            {{ dashboardMain.mkThree.street.dictionary }} onjuist
          </span>
        </template>
        
        <a-auto-complete
          allowClear
          style="width: 100%; background: transparent"
          size="large"
          id="street"
          v-model="dashboardMain.mkThree.street.name"
          :filter-option="filterOption"
          @focus="$emit('focusHandler', 'street', true, 'mkThree')"
          @dropdownVisibleChange="updateList"
          @blur="$emit('focusHandler', 'street', false, 'mkThree')"
          @select="selectHandler"
          optionLabelProp="value"
          :disabled="isEveryProductSendToWarehouse"
          ref="autoCompleteSelect"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in addressList"
              :key="item.id"
              :value="item.street"
              :title="item.street"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <p style="margin: 0">
                  {{ item.street }}
                </p>
                <div>
                  <CustomIcon
                    type="edit"
                    @click="editTemplateHandler(item.id)" 
                  />
                  <a-icon
                    type="close"
                    style="color: orange; margin-left: 15px"
                    @click.stop="deleteAddressFromAutocomplete(item.id)"
                  />
                </div>
              </div>
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-item>
  
      <label for="street"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.street.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.street.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="(valid.houseNumber.name.$dirty && valid.houseNumber.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.houseNumber.name.$dirty && valid.houseNumber.name.$invalid"
          >
            {{ dashboardMain.mkThree.houseNumber.dictionary }} onjuist
          </span>
        </template>
        <a-input
          allow-clear
          size="large"
          style="width: 100%; background: transparent"
          id="houseNumber"
          v-model="dashboardMain.mkThree.houseNumber.name"
          @focus="$emit('focusHandler', 'houseNumber', true, 'mkThree')"
          @blur="$emit('focusHandler', 'houseNumber', false, 'mkThree')"
          :disabled="isEveryProductSendToWarehouse"
        >
        </a-input>
      </a-form-item>
      <label for="dhlInsuredFloat"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.houseNumber.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.houseNumber.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="(valid.addition.name.$dirty && valid.addition.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.addition.name.$dirty && valid.addition.name.$invalid"
          >
            {{ dashboardMain.mkThree.addition.dictionary }} onjuist
          </span>
        </template>
        <a-input
          allow-clear
          size="large"
          style="width: 100%; background: transparent"
          id="addition"
          v-model="dashboardMain.mkThree.addition.name"
          @focus="$emit('focusHandler', 'addition', true, 'mkThree')"
          @blur="$emit('focusHandler', 'addition', false, 'mkThree')"
          :disabled="isEveryProductSendToWarehouse"
        >
        </a-input>
      </a-form-item>
      <label for="dhlInsuredFloat"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.addition.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.addition.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="(valid.postcode.name.$dirty && valid.postcode.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.postcode.name.$dirty && valid.postcode.name.$invalid"
          >
            {{ dashboardMain.mkThree.postcode.dictionary }} onjuist
          </span>
        </template>
        <a-input
          allow-clear
          size="large"
          style="width: 100%; background: transparent"
          id="postcode"
          v-model="dashboardMain.mkThree.postcode.name"
          @focus="$emit('focusHandler', 'postcode', true, 'mkThree')"
          @blur="$emit('focusHandler', 'postcode', false, 'mkThree')"
          :disabled="isEveryProductSendToWarehouse"
        >
        </a-input>
      </a-form-item>
      <label for="postcode"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.postcode.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.postcode.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="(valid.city.name.$dirty && valid.city.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.city.name.$dirty && valid.city.name.$invalid"
          >
            {{ dashboardMain.mkThree.city.dictionary }} onjuist
          </span>
        </template>
        <a-input
          allow-clear
          size="large"
          style="width: 100%; background: transparent"
          id="city"
          v-model="dashboardMain.mkThree.city.name"
          @focus="$emit('focusHandler', 'city', true, 'mkThree')"
          @blur="$emit('focusHandler', 'city', false, 'mkThree')"
          :disabled="isEveryProductSendToWarehouse"
        >
        </a-input>
      </a-form-item>
      <label for="city"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.city.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.city.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="dashboardMain.mkThree.lvb.name"
    >
      <a-form-item
        :validate-status="(valid.lvbNumeric.name.$dirty && valid.lvbNumeric.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.lvbNumeric.name.$dirty && valid.lvbNumeric.name.$invalid"
          >
            {{ dashboardMain.mkThree.lvbNumeric.dictionary }} onjuist
          </span>
        </template>
        <a-select
          size="large"
          style="width: 100%; background: transparent"
          id="lvbNumeric"
          v-model="dashboardMain.mkThree.lvbNumeric.name"
          @focus="$emit('focusHandler', 'lvbNumeric', true, 'mkThree')"
          @blur="$emit('focusHandler', 'lvbNumeric', false, 'mkThree')"
        >
          <a-select-option
            v-for="option in dashboardMain.mkThree.lvbNumeric.dataSource"
            :key="option.id"
            :value="JSON.stringify(option)">
            Stad: {{ option.city }}; Land: {{ option.country }}; Postcode: {{ option.postcode }}; Straat:
            {{ option.street }}; Huisnummer: {{ option.house_number }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <label for="lvbNumeric"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.lvbNumeric.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.lvbNumeric.dictionary }}
      </label>
    </div>
    <div 
      v-if="!isShipTransportType"
      class="formWrapper__form--inputWrapper"
    >
      <a-form-item
        :validate-status="(valid.dhlInsured.name.$dirty && valid.dhlInsured.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.dhlInsured.name.$dirty && valid.dhlInsured.name.$invalid"
          >
            {{ dashboardMain.mkThree.dhlInsured.dictionary }} onjuist
          </span>
        </template>
        <a-tooltip>
          <template slot="title">
            De bijkomende kosten van het verzekeren zijn 0,75% van de orderwaarde met een minimum van €10,-.
          </template>
          <a-select
            size="large"
            style="width: 100%; background: transparent"
            id="dhlInsured"
            v-model="dashboardMain.mkThree.dhlInsured.name"
            @focus="$emit('focusHandler', 'dhlInsured', true, 'mkThree')"
            @blur="$emit('focusHandler', 'dhlInsured', false, 'mkThree')"
            :disabled="isEveryProductSendToWarehouse"
          >
            <a-select-option :value="1">
              Ja
            </a-select-option>
            <a-select-option :value="0">
              Nee
            </a-select-option>
          </a-select>
        </a-tooltip>
      </a-form-item>
      <label for="dhlInsured"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.dhlInsured.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.dhlInsured.dictionary }}
      </label>
    </div>
    <div
      class="formWrapper__form--inputWrapper"
      v-if="dashboardMain.mkThree.dhlInsured.name"
    >
      <a-form-item
        :validate-status="(valid.dhlInsuredFloat.name.$dirty && valid.dhlInsuredFloat.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.dhlInsuredFloat.name.$dirty && valid.dhlInsuredFloat.name.$invalid"
          >
            {{ dashboardMain.mkThree.dhlInsuredFloat.dictionary }} onjuist
          </span>
        </template>
        <a-input
          allow-clear
          size="large"
          style="width: 100%; background: transparent"
          id="dhlInsuredFloat"
          v-model="dashboardMain.mkThree.dhlInsuredFloat.name"
          @focus="$emit('focusHandler', 'dhlInsuredFloat', true, 'mkThree')"
          @blur="$emit('focusHandler', 'dhlInsuredFloat', false, 'mkThree')"
        >
        </a-input>
      </a-form-item>
      <label for="dhlInsuredFloat"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.dhlInsuredFloat.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.dhlInsuredFloat.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(valid.countryIndex.name.$dirty && valid.countryIndex.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="valid.countryIndex.name.$dirty && valid.countryIndex.name.$invalid"
          >
            {{ dashboardMain.mkThree.countryIndex.dictionary }} onjuist
          </span>
        </template>

        <a-select
          size="large"
          style="width: 100%; background: transparent"
          id="countryIndex"
          v-model="dashboardMain.mkThree.countryIndex.name"
          @focus="$emit('focusHandler', 'countryIndex', true, 'mkThree')"
          @blur="$emit('focusHandler', 'countryIndex', false, 'mkThree')"
          :disabled="isEveryProductSendToWarehouse"
        >
          <a-select-option :value="1">
            Nederland
          </a-select-option>
          <a-select-option :value="2">
            België
          </a-select-option>
        </a-select>
      </a-form-item>
      <label for="dhlInsured"
             class="formWrapper__form--label"
             :class="dashboardMain.mkThree.countryIndex.isFocused ? 'focused' : ''"
      >
        {{ dashboardMain.mkThree.countryIndex.dictionary }}
      </label>
    </div>
    <div 
      class="form__checkbox"
      v-if="!dashboardMain.mkThree.lvb.name"
    >
      <a-checkbox
        id="termsCheckbox"
        @change="checkboxHandler"
        :checked="dashboardMain.mkThree.saveDeliveryData"
        :disabled="isCheckboxDisabled || isEveryProductSendToWarehouse"
      >
        Adres opslaan
      </a-checkbox>
    </div>
    <CustomModalWindow
      :visible="isOpenModal"
      okText="Verandering"
      centered
      title="Bezorggegevens bewerken"
      @cancel="closeModal"
      @ok="saveEditedTemplate"
      :loading="isChangingTemplateData"
      :closable="!isChangingTemplateData"
      :disabledOkBtn="valid.templateData.$invalid"
    >
      <CustomInput
        v-for="(value, name) of templateData"
        :fieldData="value"
        :fieldName="name"
        :formFields="templateData"
        :validation="valid.templateData[name]"
        :key="name + value.id"
      /> 
    </CustomModalWindow>
    <CustomSpinner 
      v-if="isLoadingTemplate" 
      size="150" 
      centered
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState } from "vuex";
import clearTemplateFields from "../../mixins/clearTemplateFields";
import editTemplates from "../../mixins/editTemplates";

export default {
  name: "NewBookingThirdStep",
  props: {
    valid: Object
  },
  beforeDestroy() {
    this.clearErrorAndTempalteNotificationHandler();
  },
  mixins: [clearTemplateFields, editTemplates],
  data() {
    return {
      templateFields: [
        "street",
        "houseNumber", 
        "city", 
        "countryIndex", 
        "postcode", 
        "addition"
      ],
      outerField: 'mkThree',
      resetValidateField: 'street',
      parentModalStateField: 'isOpenModalAtThirdStep',
    }
  },
  watch: {
    isCheckboxDisabled (bool) {
      bool && this.saveDeliveryDataHandler(false);
    },
  },
  methods: {
    ...mapMutations(['saveDeliveryDataHandler', 'autoFillThirdStep', 'clearAutofilledData', 'clearErrorAndTempalteNotificationHandler']),
    ...mapActions(['getLvbLocationList', 'setDeliveryAddressFromList', 'getDeliveryAddressList', 'deleteAddressItem', 'editDeliveryTemplateItem']),
    lvbChangeHandler(val) {
      if (val) {
        this.dashboardMain.mkThree.addition.name = ''
        this.dashboardMain.mkThree.addition.isFocused = false
        this.dashboardMain.mkThree.houseNumber.name = ''
        this.dashboardMain.mkThree.houseNumber.isFocused = false
        this.dashboardMain.mkThree.street.name = ''
        this.dashboardMain.mkThree.street.isFocused = false
      } else {
        this.dashboardMain.mkThree.lvbNumeric.name = ''
        this.dashboardMain.mkThree.lvbNumeric.isFocused = false
      }
    },
    getTemplatesItem (id, bool) {
      return this.setDeliveryAddressFromList({id, isToAutoFillData: bool})
    },
    editTemplateItem (id) {
      return this.editDeliveryTemplateItem(id)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    updateList(bool) {
      bool && this.getDeliveryAddressList(); 
    },
    checkboxHandler(e) {
      this.saveDeliveryDataHandler(e.target.checked)
    },
    async deleteAddressFromAutocomplete(id) {
      await this.deleteAddressItem(id);
      this.getDeliveryAddressList();
    },
  },
  created() {
    this.getLvbLocationList();
    this.isEveryProductSendToWarehouse && this.autoFillThirdStep();
  },
  computed: {
    ...mapState({
      dashboardMain: state => state.dashboardMain,
      addressList: state => state.dashboardMain.mkThree.street.dataSource,
      mainTemplateField: state => state.dashboardMain.mkThree.street.name,
      templateData: state => state.dashboardMain.mkThree.templateData,
      isChangingTemplateData: state => state.dashboardMain.isChangingTemplateData,
      isLoadingTemplate: state => state.dashboardMain.isLoadingTemplate,
    }),
    isCheckboxDisabled () {
      return this.addressList.some(({ street }) => street.toLowerCase() === this.dashboardMain.mkThree.street.name.toLowerCase());
    },
    isEveryProductSendToWarehouse () {
      return this.dashboardMain.mkTwo.products.every(({ leaveInWarehouse, subProductObj: { product_subs } }) => {
        return leaveInWarehouse.name === 2 || product_subs.length && product_subs.every(({quantityBoxes, quantityInBooking}) => quantityBoxes.name === quantityInBooking.name);
      });
    },
    isShipTransportType () {
      return this.dashboardMain.mkOne.transportType.name === 2;
    },
  }, 
}
</script>

<style scoped>

</style>

