<template>
  <div>
    <a-collapse
      expand-icon-position="left"
      :class="{ close: !subProducts.length }"
      ref="openPanel"
      v-model="activeKey"
    >
      <a-collapse-panel
        :show-arrow="!!subProducts.length"
        :disabled="!subProducts.length"
        header="Product variant"
        :key="productId.toString()"
      >
        <a-tooltip 
          slot="extra"
          placement="left"
          style="margin-right: 10px"
        >
          <template slot="title">
            <h3 class="informationText" style="color: #fff">
              Zodra jij een product variant toevoegt, dien jij het aantal dozen per product variant aan te geven. 
              Het totaal van alle dozen van jouw product varianten wordt automatisch weergegeven bij het kopje 'Hoeveelheid dozen'. 
              Mocht je perongeluk op een product variant geklikt hebben, kan jij deze weer verwijderen door op het prullenbakje te klikken.
            </h3>
          </template>
          <a-icon type="info-circle" style="color: black"/>
        </a-tooltip>
        <a-button
          slot="extra"
          type="primary"
          shape="circle"
          icon="plus"
          size="small"
          @click.stop="addSubProductHandler"
          title="Voeg product types toe"
        />
        <div
          class="subProduct__wrapper"
          v-for="(subProd, idx) in subProducts"
          :key="subProd.key"
        >
          <div class="subProduct__delete-subProd">
            <div>
              {{ `Variant #${idx + 1}` }}
            <a-tooltip style="margin-left: 5px">
              <template slot="title">
                <h3 class="informationText" style="color: #fff">Vul hier in of het om de rode of groene kleur gaat, grote maat of kleine maat etc.</h3>
              </template>
              <a-icon type="info-circle" style="color: black"/>
            </a-tooltip>
            </div>
            <a-button
              icon="delete"
              slot="extra"
              type="link"
              size="small"
              class="subProduct__delete-icon"
              @click.stop="deleteSubProduct({ productId, subProdId: idx })"
            />
          </div>

          <div class="formWrapper__form--inputWrapper">
            <a-form-item
              :validate-status="
                validationRuleForField(idx, 'description') ? 'error' : 'success'
              "
            >
              <template #help>
                <span v-show="validationRuleForField(idx, 'description')">
                  {{ subProd.description.dictionary }} onjuist
                </span>
              </template>

              <a-input
                allow-clear
                size="large"
                class="formWrapper__form--input"
                v-model="subProd.description.name"
                @focus="focusController(true, subProd.description, 'description', idx)"
                @blur="focusController(false, subProd.description, 'description', idx)"
              />
            </a-form-item>

            <label
              class="formWrapper__form--label"
              :class="subProd.description.isFocused ? 'focused' : ''"
            >
              {{ subProd.description.dictionary }}
            </label>
          </div>

          <div class="form__inlineInputs">
            <div class="formWrapper__form--inputWrapper">
              <a-form-item
                :validate-status="
                  validationRuleForField(idx, 'quantityBoxes')
                    ? 'error'
                    : 'success'
                "
              >
                <template #help>
                  <span v-show="validationRuleForField(idx, 'quantityBoxes')">
                    {{ subProd.quantityBoxes.dictionary }} onjuist
                  </span>
                </template>

                <a-input-number
                  size="large"
                  class="formWrapper__form--input"
                  v-model="subProd.quantityBoxes.name"
                  @focus="focusController(true, subProd.quantityBoxes, 'quantityBoxes', idx)"
                  @blur="focusController(false, subProd.quantityBoxes, 'quantityBoxes', idx)"
                  :min="1"
                  @change="changeSubprodCountHandler(idx, 'quantityInBooking')"
                />
              </a-form-item>

              <label
                class="formWrapper__form--label"
                :class="subProd.quantityBoxes.isFocused ? 'focused' : ''"
              >
                {{ subProd.quantityBoxes.dictionary }}
              </label>
            </div>

            <div
              v-if="subProd.leaveInWarehouse.name && isShipDeliveryMethod"
              class="formWrapper__form--inputWrapper"
            >
              <a-form-item
                :validate-status="
                  validationRuleForField(idx, 'quantityInBooking')
                    ? 'error'
                    : 'success'
                "
              >
                <template #help>
                  <span v-show="validationRuleForField(idx, 'quantityInBooking')">
                    {{ subProd.quantityInBooking.dictionary }} onjuist
                  </span>
                </template>

                <a-input-number
                  size="large"
                  class="formWrapper__form--input descr"
                  v-model="subProd.quantityInBooking.name"
                  @focus="focusController(true, subProd.quantityInBooking, 'quantityInBooking', idx)"
                  @blur="focusController(false, subProd.quantityInBooking, 'quantityInBooking', idx)"
                  :min="1"
                  :max="subProd.quantityBoxes.name"
                  :disabled="subProd.leaveInWarehouse.name === 2"
                />
              </a-form-item>

              <label
                class="formWrapper__form--label"
                :class="subProd.quantityInBooking.isFocused ? 'focused' : ''"
              >
                {{ subProd.quantityInBooking.dictionary }}
              </label>
            </div>
          </div>

          <div 
            v-if="isShipDeliveryMethod"
            class="formWrapper__form--inputWrapper"
          >
            <a-form-item
              :validate-status="
                validationRuleForField(idx, 'leaveInWarehouse')
                  ? 'error'
                  : 'success'
              "
            >
              <a-select
                size="large"
                style="background: transparent; width: 100%"
                v-model="subProd.leaveInWarehouse.name"
                @focus="focusController(true, subProd.leaveInWarehouse, 'leaveInWarehouse', idx)"
                @blur="focusController(false, subProd.leaveInWarehouse, 'leaveInWarehouse', idx)"
                @change="changeSubprodCountHandler(idx, 'quantityInBooking')"
              >
                <a-select-option :value="2">Ja, alles</a-select-option>
                <a-select-option :value="1">Ja, een deel</a-select-option>
                <a-select-option :value="0">Nee</a-select-option>
              </a-select>
            </a-form-item>

            <label
              class="formWrapper__form--label"
              :class="subProd.leaveInWarehouse.isFocused ? 'focused' : ''"
            >
              {{ subProd.leaveInWarehouse.dictionary }}
            </label>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { InputNumber } from "ant-design-vue";

export default {
  name: "SubProduct",
  props: {
    subProducts: Array,
    productId: Number,
    validators: Object,
  },
  components: {
    AInputNumber: InputNumber,
  },
  data() {
    return {
      activeKey: [],
    }
  },
  methods: {
    ...mapMutations(["addSubProduct", "deleteSubProduct", "clearSubProdWarehouseValue", "changeSubprodFieldCount"]),
    focusController(toFocus, fieldData, element, idx) {
      this.$store.commit("FOCUS_HANDLER", {
        fieldData,
        toFocus,
        validation:
          this.validators.products.$each[this.productId].subProductObj
            .product_subs.$each[idx][element].name,
      });
    },
    validationRuleForField(idx, element) {
      return (
        this.validators.products.$each[this.productId].subProductObj
          .product_subs.$each[idx][element].name.$dirty &&
        this.validators.products.$each[this.productId].subProductObj
          .product_subs.$each[idx][element].name.$invalid
      );
    },
    changeSubprodCountHandler (idx, fieldName) {
      const addAllSubproductsToWarehouse = this.subProducts[idx].leaveInWarehouse.name === 2;
      const quantityBoxes = this.subProducts[idx].quantityBoxes.name;
      const chosenManualyWayToAddSubProductCount = this.subProducts[idx].leaveInWarehouse.name === 1;
      const chosenNotToSendToWarehouseAnySubProduct = this.subProducts[idx].leaveInWarehouse.name === 0 && this.subProducts[idx].quantityInBooking.name && this.subProducts[idx].quantityBoxes.name;
      addAllSubproductsToWarehouse && quantityBoxes && this.changeSubprodFieldCount({ key: this.productId, subProdIdx: idx, fieldName, value: quantityBoxes });
      (chosenManualyWayToAddSubProductCount || chosenNotToSendToWarehouseAnySubProduct) && this.clearSubProdWarehouseValue({ key: this.productId, subProdIdx: idx });
    },
    addSubProductHandler () {
      const isActiveKeyAlreadyExist = this.activeKey.includes(this.productId.toString());
      !isActiveKeyAlreadyExist && this.activeKey.push(this.productId.toString());
      this.addSubProduct(this.productId);
    },
  },
  computed: {
    ...mapState({
      state: (state) => state.dashboardMain.mkTwo,
      transportType: state => state.dashboardMain.mkOne.transportType.name,
    }),
    isShipDeliveryMethod () {
      return this.transportType === 2;
    }
  },
};
</script>

<style lang="scss" scoped>
.subProduct {
  &__wrapper {
    margin-top: 10px;
    border-bottom: 2px solid #d9d9d9;
  }
  &__delete-subProd {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
  }
  &__delete-icon {
    width: 24px !important;
    height: 20px !important;
  }
}
::v-deep .close .ant-collapse-content.ant-collapse-content-active {
  display: none;
}
::v-deep .ant-input-number{
  width: 100%;
}
</style>
