<template>
  <div>
    <div v-if="dashboard.currentStage && dashboard.currentStage !== 5 && !dashboard.isLoading">
      <div>
        <h1>Boeking aanmaken</h1>
        <h4 class="subtitle">Maak hier je boeking aan om je goederen te kunnen versturen</h4>
        <div class="form__progress">
          <div class="form__progress--title">
            <h2>
              <template v-if="dashboard.currentStage === 1">Gegevens van de leverancier</template>
              <template v-if="dashboard.currentStage === 2">Productgegevens</template>
              <template v-if="dashboard.currentStage === 3">Levering</template>
              <template v-if="dashboard.currentStage === 4">Overzicht boeking</template>
            </h2>
            <p>
              <span>{{ dashboard.currentStage }}</span>/{{ dashboard.stageCount }}
            </p>
          </div>
          <a-progress
            type="line"
            size="small"
            strokeColor="#FFA300"
            :percent="(dashboard.currentStage / dashboard.stageCount) * 100"
            :show-info="false"
          />
          <p class="form__privacy">
            <template v-if="dashboard.currentStage === 1">
              Voer hier de gegevens in van de leverancier van je goederen
            </template>
            <template v-if="dashboard.currentStage === 2">
              Voer hier de gegevens in van de product(en) die je wilt importeren
            </template>
            <template v-if="dashboard.currentStage === 3">
              Voer hier de gegevens in voor de levering van je goederen
            </template>
            <template v-if="dashboard.currentStage === 4">
              Controleer en bevestig je ingevoerde gegevens, dit is hierna niet meer aan te passen
            </template>
          </p>
        </div>
        <NewBookingFirstStep
          v-if="dashboard.currentStage === 1"
          :valid="$v.dashboard.mkOne"
          :secondStepValid="$v.dashboard.mkTwo"
          @focusHandler="(providerName, bool, step) => focusHandler(providerName, bool, step)"
          @modalStateWatcher="modalStateWatcher"
        />
        <NewBookingSecondStep
          v-if="dashboard.currentStage === 2"
          :valid="$v.dashboard.mkTwo"
          @focusHandler="(providerName, bool, step, isArray, arrayName, index) => focusHandler(providerName, bool, step, isArray, arrayName, index)"
        />
        <NewBookingThirdStep
          v-if="dashboard.currentStage === 3"
          :valid="$v.dashboard.mkThree"
          @focusHandler="(providerName, bool, step) => focusHandler(providerName, bool, step)"
          @modalStateWatcher="modalStateWatcher"
        />
        <NewBookingFourthStep
          v-if="dashboard.currentStage === 4"
          :valid="$v.dashboard.mkThree"
          @focusHandler="(providerName, bool, step) => focusHandler(providerName, bool, step)"
        />
      </div>
      
      <a-button-group size="large" class="form__steps">
        <a-button type="primary"
                  :disabled="dashboard.currentStage === 1"
                  @click="backButtonHandler"
        >
          <a-icon type="left"/>
          Vorige stap
        </a-button>
        <a-button
          type="primary"
          @click="nextStageHandler"
        >
          Volgende stap
          <a-icon type="right"/>
        </a-button>
      </a-button-group>
    </div>
    <div v-if="dashboard.currentStage === 5 && !dashboard.isLoading">
      <Success @next="afterSuccessHandler" />
    </div>
    <CustomSpinner 
      v-if="dashboard.isLoading" 
      size="100" 
      centered
    />
  </div>
</template>

<script>
import NewBookingFirstStep from "@/components/makeNewBooking/NewBookingFirstStep";
import NewBookingSecondStep from "@/components/makeNewBooking/NewBookingSecondStep";
import NewBookingThirdStep from "@/components/makeNewBooking/NewBookingThirdStep";
import NewBookingFourthStep from "@/components/makeNewBooking/NewBookingFourthStep";
import {mapActions, mapMutations, mapState} from "vuex";
import {
  email,
  maxLength,
  minLength,
  required,
  maxValue,
  requiredIf,
  decimal,
  integer,
  withParams,
  minValue,
} from "vuelidate/lib/validators";
import SuperAdmin from "@/components/makeNewBooking/SuperAdmin";
import {isDecimalWithDotOrComma, customMaxValue} from "@/customValidators/validators.js"
import NotificationModal from "@/components/dashboard/NotificationModal";
import Success from "@/components/warehouse/Success";
import CustomSpinner from "@/components/customComponents/CustomSpinner.vue";
const isPhone = (value) => !value || /^\+?[0-9]+$/.test(value)

export default {
  components: {
    NewBookingFirstStep,
    NewBookingSecondStep,
    NewBookingThirdStep,
    NewBookingFourthStep,
    SuperAdmin,
    NotificationModal,
    Success,
    CustomSpinner,
  },
  data() {
    return {
      isOpenModalAtFirstStep: false,
      isOpenModalAtThirdStep: false,
    }
  },
  validations() {
    return {
      dashboard: {
        mkOne: {
          providerName: {
            name: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(100),
            }
          },
          providerPhone: {
            name: {
              phoneValid: isPhone,
              maxLength: maxLength(30),
              minLength: minLength(7)
            },
          },
          providerEmail: {
            name: {
              required,
              email,
              maxLength: maxLength(250),
            }
          },
          providerStreetHouseName: {
            name: {
              required,
              minLength: minLength(7),
              maxLength: maxLength(100),
            }
          },
          providerPostCode: {
            name: {
              minLength: minLength(4),
              maxLength: maxLength(30),
            }
          },
          transportType: {
            name: {
              required
            }
          },
          incotermsRule: {
            name: {
              required
            }
          },
          recipientAddress: {
            name: {
              required,
              minLength: minLength(4),
              maxLength: maxLength(100),
            }
          },
          isDangerousMkOne: {
            name: {
              required
            }
          },
          customWarehouseAddress: {
            name: {
              required: requiredIf(() => this.dashboard.mkOne.warhouseId.name === 0),
              maxLength: maxLength(30),
            }
          },
          warhouseId: {
            name: {
              required
            }
          },
          templateData: {
            providerName: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                minLength: minLength(3),
                maxLength: maxLength(100),
              }
            },
            providerPhone: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                phoneValid: isPhone,
                maxLength: maxLength(30),
                minLength: minLength(7)
              },
            },
            providerEmail: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                email,
                maxLength: maxLength(250),
              }
            },
            providerStreetHouseName: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                minLength: minLength(7),
                maxLength: maxLength(100),
              }
            },
            providerPostCode: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                minLength: minLength(4),
                maxLength: maxLength(30),
              }
            },
            recipientAddress: {
              name: {
                required: requiredIf(() => this.isOpenModalAtFirstStep),
                minLength: minLength(4),
                maxLength: maxLength(100),
              }
            },
          },
        },
        mkTwo: {
          products: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(30),
            $each: {
              $trackBy: 'key',
              description: {
                name: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(150),
                }
              },
              hsCode: {
                name: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(30),
                }
              },
              enaCode: {
                name: {
                  minLength: minLength(1),
                  maxLength: maxLength(30),
                }
              },
              pricePerPiece: {
                name: {
                  required,
                  isDecimalWithDotOrComma,
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              otherCosts: {
                name: {
                  required,
                  isDecimalWithDotOrComma,
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              totalValue: {
                name: {
                  required,
                  isDecimalWithDotOrComma,
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              quantityInBooking: {
                name: {
                  required,
                  integer,
                  minValue: minValue(1),
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              quantityBoxes: {
                name: {
                  required,
                  integer,
                  minValue: minValue(1),
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              grossWeight: {
                name: {
                  required,
                  isDecimalWithDotOrComma,
                  customMaxValue: customMaxValue(100000),
                  maxLength: maxLength(9),
                  minLength: minLength(1)
                }
              },
              cbm: {
                name: {
                  required,
                  isDecimalWithDotOrComma,
                  customMaxValue: customMaxValue(100000),
                  minLength: minLength(1),
                  maxLength: maxLength(30),
                }
              },
              leaveInWarehouse: {
                name: {
                  required: requiredIf(function (vm) {
                    return this.dashboard.mkOne.transportType.name === 2
                  })
                }
              },
              countBoxesToLeaveInWarehouse: {
                name: {
                  integer,
                  required: requiredIf(function (vm) {
                    let index = this.dashboard.mkTwo.products.findIndex(obj => obj.countBoxesToLeaveInWarehouse === vm)

                    if (index !== -1) {
                      return !!this.dashboard.mkTwo.products[index].leaveInWarehouse.name
                    }
                  }),
                  customMaxValue: (val, vm) => {
                    let index = this.dashboard.mkTwo.products.findIndex(obj => obj.countBoxesToLeaveInWarehouse === vm)

                    if (index !== -1) {
                      return +val <= +this.dashboard.mkTwo.products[index].quantityBoxes.name
                    }
                    return true
                  },
                  minValue: minValue(1),
                  maxLength: maxLength(30)
                }
              },
              isDangerous: {
                name: {
                  required
                }
              },       
              subProductObj: {
                product_subs: {
                  $each: {
                    $trackBy: 'key',
                    description: {
                      name: {
                        required,
                        minLength: minLength(1),
                        maxLength: maxLength(45),
                      }
                    },
                    quantityBoxes: {
                      name: {
                        required,
                        integer,
                        minValue: minValue(1),
                        customMaxValue: customMaxValue(100000),
                        maxLength: maxLength(9),
                        minLength: minLength(1),
                      }
                    },
                    quantityInBooking: {
                      name: {
                        required: requiredIf(function (vm) {
                          const localState = this.dashboard.mkTwo.products;
                          const productIdx = localState.findIndex(product => 
                            product.subProductObj.product_subs.some(subProduct => subProduct.quantityInBooking === vm)
                          );
                          const subProductIdx = localState[productIdx].subProductObj.product_subs.findIndex(subProduct => subProduct.quantityInBooking === vm);
                          const isDeliveryToWarehouse = !!(localState[productIdx].subProductObj.product_subs[subProductIdx].leaveInWarehouse.name);
                          
                          return isDeliveryToWarehouse;
                        }),
                        integer,
                        minValue: minValue(1),
                        maxLength: maxLength(30),
                        customLessThanSubProdCount: (val, vm) => {
                          const localState = this.dashboard.mkTwo.products;
                          const productIdx = localState.findIndex(product => 
                            product.subProductObj.product_subs.some(subProduct => subProduct.quantityInBooking === vm)
                          );
                          const subProductIdx = localState[productIdx].subProductObj.product_subs.findIndex(subProduct => subProduct.quantityInBooking === vm);
                          const subProdCount = localState[productIdx].subProductObj.product_subs[subProductIdx].quantityBoxes.name;
                          const isDeliveryToWarehouse = localState[productIdx].subProductObj.product_subs[subProductIdx].leaveInWarehouse.name;

                          return !((subProdCount && isDeliveryToWarehouse) && +val > +subProdCount)
                        },
                      }
                    },
                    leaveInWarehouse: {
                      name: {
                        required: requiredIf(function () {
                          return this.dashboard.mkOne.transportType.name === 2;
                        }),
                      }
                    }
                  }
                }
              }
            }
          },
        },
        mkThree: {
          templateData: {
            street: {
              name: {
                required: requiredIf(() => this.isOpenModalAtThirdStep),
                maxLength: maxLength(30),
              }
            },
            postcode: {
              name: {
                required: requiredIf(() => this.isOpenModalAtThirdStep),
                maxLength: maxLength(20),
              }
            },
            houseNumber: {
              name: {
                required: requiredIf(() => this.isOpenModalAtThirdStep),
                maxLength: maxLength(30),
              }
            }, 
            city: {
              name: {
                required: requiredIf(() => this.isOpenModalAtThirdStep),
                maxLength: maxLength(30),
              }
            },
            addition: {
              name: {
                maxLength: maxLength(30),
              }
            },
            countryIndex: {
              name: {
                required: requiredIf(() => this.isOpenModalAtThirdStep),
              }
            },
          },
          lvb: {
            name: {
              required
            }
          },
          lvbNumeric: {
            name: {
              required: requiredIf(function () {
                return !!this.dashboard.mkThree.lvb.name
              }),
              maxLength: maxLength(170),
            }
          },
          countryIndex: {
            name: {
              required
            }
          },
          dhlInsured: {
            name: {
              required: requiredIf(function () {
                return this.dashboard.mkOne.transportType.name === 1;
              }),
            }
          },
          street: {
            name: {
              required: requiredIf(function () {
                return !(!!this.dashboard.mkThree.lvb.name)
              }),
              maxLength: maxLength(30),
            }
          },
          postcode: {
            name: {
              required: requiredIf(function () {
                return !(!!this.dashboard.mkThree.lvb.name)
              }),
              maxLength: maxLength(20),
            }
          },
          houseNumber: {
            name: {
              required: requiredIf(function () {
                return !(!!this.dashboard.mkThree.lvb.name)
              }),
              maxLength: maxLength(30),
            }
          },
          remarks: {
            name: {
              maxLength: maxLength(250),
            }
          },
          personalRef: {
            name: {
              maxLength: maxLength(25),
            }
          },
          city: {
            name: {
              required: requiredIf(function () {
                return !(!!this.dashboard.mkThree.lvb.name)
              }),
              maxLength: maxLength(30),
            }
          },
          addition: {
            name: {
              maxLength: maxLength(30),
            }
          },
          dhlInsuredFloat: {
            name: {
              required: requiredIf(function () {
                return !!this.dashboard.mkThree.dhlInsured.name
              }),
              maxLength: maxLength(30),
              isDecimalWithDotOrComma,
            }
          },
          bookingReference: {
            name: {
              maxLength: maxLength(50),
            }
          },
          userSearch: {
            name: {
              maxLength: maxLength(50),
            }
          }
        }
      },
    }
  },
  computed: {
    ...mapState({
      dashboard: state => state.dashboardMain
    })
  },
  created() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  methods: {
    ...mapActions([
      'firstStage',
      'secondStage',
      'thirdStage',
      'fourthStage',
      'saveRecipient',
      'saveDeliveryData'
    ]),
    ...mapMutations([
      'goBack',
      'clearDocumentErrors'
    ]),
    onBeforeUnload(e) {
      if (this.dashboard.currentStage === 5) {
        return;
      };
      e.preventDefault();
      const confirmationMessage = 'Weet je zeker dat je de pagina opnieuw wilt laden? De ingevoerde gegevens worden niet opgeslagen.';
      (e || window.event).returnValue = confirmationMessage
      return confirmationMessage;
    },
    focusHandler(el, toFocus, step, isArray, arrayName, arrayIdx) {
        if (isArray) {
        if (!toFocus) {
          this.$v.dashboard[step][arrayName].$each[arrayIdx][el].$touch()
        }
        if (toFocus || (this.dashboard[step][arrayName][arrayIdx][el].name || typeof this.dashboard[step][arrayName][arrayIdx][el].name === 'number')) {
          this.dashboard[step][arrayName][arrayIdx][el].isFocused = true
          return;
        }
        this.dashboard[step][arrayName][arrayIdx][el].isFocused = false
      } else {
        if (!toFocus) {
          this.$v.dashboard[step][el].$touch()
        }
        if (toFocus || (this.dashboard[step][el].name || typeof this.dashboard[step][el].name === 'number')) {
          this.dashboard[step][el].isFocused = true
          return;
        }
        this.dashboard[step][el].isFocused = false
      }
    },
    async nextStageHandler() {
      if (this.dashboard.currentStage === 1) {
        this.$v.dashboard.mkOne.$touch()

        if (!this.$v.dashboard.mkOne.$invalid) {
          if (this.dashboard.mkOne.saveProvider) {
            await this.saveRecipient()
            if (this.dashboard.mkOne.errorMessage) {
              return
            }
          }
          this.firstStage()
        }
        return
      }
      if (this.dashboard.currentStage === 2) {
        this.clearDocumentErrors();
        this.$v.dashboard.mkTwo.products.$each.$touch()
        let res = false;
        let conditionHasFile = this.dashboard.mkTwo.document.file.length;
        let conditionHasNotError = !this.dashboard.mkTwo.document.errorText.length;
        if (conditionHasFile && conditionHasNotError) {
          res = true
        }
        if (this.$v.dashboard.mkTwo.products.$each.$invalid) {
          this.dashboard.mkTwo.error = 'Er is iets fout gegaan bij de invoer van je producten '
          setTimeout(() => {
            this.dashboard.mkTwo.error = ''
          }, 5000);
          return
        }
        if (!res) {
          this.dashboard.mkTwo.error = 'Upload a.u.b. de commerciële factuur'
          setTimeout(() => {
            this.dashboard.mkTwo.error = ''
          }, 5000);
          return
        }
        if (!this.$v.dashboard.mkTwo.products.$each.$invalid && res) this.secondStage()
        return
      }
      if (this.dashboard.currentStage === 3) {
        this.$v.dashboard.mkThree.$touch()
        if (!this.$v.dashboard.mkThree.$invalid) {
          if (this.dashboard.mkThree.saveDeliveryData) {
            await this.saveDeliveryData()
            if (this.dashboard.mkThree.errorMessage) {
              return
            }
          }
          this.thirdStage()
        }
        return
      }
      if (this.dashboard.currentStage === 4) {
        this.$v.dashboard.mkThree.$touch();
        if (this.$v.dashboard.mkThree.$invalid) {
          return
        }
        this.fourthStage();
      }

    },
    backButtonHandler() {
      this.goBack()
    },
    modalStateWatcher (data) {
      if (!data) return;
      this[data.field] = data.value;
    },
    afterSuccessHandler () {
      window.location.assign('/dashboard/bookings')
    }
  },
  name: "MakeBooking"
}
</script>

<style scoped lang="scss">
.form {
  &__privacy {
    font-size: 16px;
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px 0;
  }

  &__progress {
    &--title {
      display: flex;
      justify-content: space-between;

      h2 {
        color: #FFA300;
        margin-bottom: 0;
        display: flex;
        align-items: flex-end;
      }

      p {
        margin-bottom: 0;
        display: flex;
        align-items: flex-end;

        span {
          color: #FFA300;
        }
      }
    }
  }
}

.subtitle {
  margin-bottom: 32px;
}
</style>
