<template>
  <div>
    <Product
      v-for="(product, idx) in dashboardMain.mkTwo.products"
      :product="product"
      :idx="idx"
      :key="product.key"
      :validators="valid"
      @focusProps="
        (el, bool, step, isArray, arrayName, index) =>
          $emit('focusHandler', el, bool, step, isArray, arrayName, index)
      "
      style="margin-bottom: 10px"
    >
      <SubProduct
        :subProducts="product.subProductObj.product_subs"
        :productId="idx"
        :validators="valid"
      />
    </Product>
    <div class="addNewProduct">
      <div></div>
      <a-button
        type="primary"
        size="large"
        class="addNewProduct__btn"
        icon="plus"
        @click="addProduct"
      />
      <div></div>
    </div>
      <a-tooltip 
        class="upload__wrapper"
        placement="right"
      >
        <h3 class="upload__title">Commerciële factuur</h3>
        <template slot="title">
          <h3 class="informationText" style="color: #fff">Je kan hier meerdere bestanden uploaden, denk aan een paklijst, MSDS bestand o.i.d.. Wel is het van belang dat je 1 leidende commerciele factuur upload, meerdere facturen voor 1 boeking kunnen wij niet verwerken.</h3>
        </template>
        <a-icon type="info-circle" style="color: black"/>
      </a-tooltip>
    <div class="uploadFile">
      <a-upload-dragger
        :disabled="dashboardMain.mkTwo.document.loadingFile"
        :fileList="documentsList"
        name="document"
        class="avatar-uploader"
        :multiple="true"
        :beforeUpload="(e) => beforeUpload(e)"
        :customRequest="(e) => getFile(e)"
        @change="(e) => handleChange(e)"
        :remove="handleRemove"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">Upload jouw documenten hier of klik om te zoeken</p>
      </a-upload-dragger>
      <a-badge
        :status="
          dashboardMain.mkTwo.document.errorText.length ? 'error' : 'success'
        "
        v-show="
          !dashboardMain.mkTwo.document.isFileLoading &&
          dashboardMain.mkTwo.document.file.length
        "
        :text="
          dashboardMain.mkTwo.document.errorText.length
            ? 'Onjuist'
            : 'Goedgekeurd'
        "
      />
      <a-spin v-if="dashboardMain.mkTwo.document.isFileLoading">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
      <a-spin v-if="dashboardMain.mkTwo.document.isFileRemoving">
        <a-icon slot="indicator" type="loading" style="font-size: 24px; color: red" spin />
      </a-spin>
      <a-list
        size="small"
        bordered
        v-show="dashboardMain.mkTwo.document.errorText.length"
        style="margin-top: 15px"
      >
        <a-list-item
          v-for="error in dashboardMain.mkTwo.document.errorText"
          :key="error.length"
        >
          <a-badge :status="'error'" :text="error" />
        </a-list-item>
      </a-list>
    </div>
    <a-alert
      :message="dashboardMain.mkTwo.error"
      type="error"
      style="margin-top: 15px"
      close-text="Close Now"
      v-if="dashboardMain.mkTwo.error.length"
    />
  </div>
</template>

<script>
import Product from "@/components/makeNewBooking/Product";
import SubProduct from "@/components/makeNewBooking/SubProduct";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Product,
    SubProduct
  },
  props: {
    valid: Object,
  },
  name: "NewBookingSecondStep",
  data() {
    return {
      loading: false,
      onSuccess: false,
      error: "",
      biggerThanMaxSize: false,
      thirdStepFields: [
        "lvb", 
        "street", 
        "houseNumber", 
        "addition", 
        "countryIndex", 
        "dhlInsured",
        "city",
        "postcode",
      ],
    };
  },
  beforeDestroy() {
    this.doDocumentEmpty();
    this.clearDocumentErrors();
  },
  methods: {
    ...mapMutations([
      "addProduct",
      "doDocumentEmpty",
      "makeDocumentErrorText",
      "clearDocumentFile",
      "clearDocumentErrors",
      "clearAutofilledData"
    ]),
    ...mapActions(["validateDocument", "removeDocument"]),
    getFile({ onSuccess }) {
      this.onSuccess = true;
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    isRepeatedFile (document) {
      return this.dashboardMain.mkTwo.document.file.some(({ file: { name } }) => document.name === name);
    },
    handleChange(info) {
      if (this.isRepeatedFile(info.file) || this.biggerThanMaxSize) {    
        return
      }

      if (this.onSuccess && info.fileList.length) {
        if (info.file.size > 10485760) {
          return;
        } else {
          this.validateDocument({ file: info.file.originFileObj });
        } 
        this.dashboardMain.mkTwo.document.isDirty = true;
        this.onSuccess = false;
        this.biggerThanMaxSize = false;
      }

      if (!info.fileList.length) {
        this.dashboardMain.mkTwo.document.isDirty = false;
        this.doDocumentEmpty();
        this.clearDocumentFile();
      }
    },

    async beforeUpload(file, name) {
      this.clearDocumentErrors();
      this.biggerThanMaxSize = false;

      if (this.isRepeatedFile(file)) {
        this.makeDocumentErrorText({
          err: ["Dit bestand is al gedownload!"],
        });
        return
      }

      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.biggerThanMaxSize = true;
        this.makeDocumentErrorText({
          err: ["Het document moet kleiner zijn dan 10 MB!"],
        });
        return;
      }
      this.biggerThanMaxSize = false;
      return !isLt10M;
    },
    handleRemove ({ uid }) {      
      this.biggerThanMaxSize = false;
      const { id } = this.dashboardMain.mkTwo.document.file.find(({ file }) => file.uid === uid)
      if (!id) return;
      this.removeDocument(id);
    }
  },
  computed: {
    ...mapState({
      dashboardMain: (state) => state.dashboardMain,
    }),
    documentsList () {
      return this.dashboardMain.mkTwo.document.file.map(item => item.file);
    },
    isEveryProductSendToWarehouse () {
      return this.dashboardMain.mkTwo.products.every(({ leaveInWarehouse, subProductObj: { product_subs } }) => {
        return leaveInWarehouse.name === 2 || product_subs.length && product_subs.every(({quantityBoxes, quantityInBooking}) => quantityBoxes.name === quantityInBooking.name);
      });
    },
  },
  watch: {
    isEveryProductSendToWarehouse (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.clearAutofilledData({step: 'mkThree', fields: this.thirdStepFields});
      }
    }
  },
};
</script>

<style scoped lang="scss">
.addNewProduct {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 20px;

  & > div {
    flex-grow: 1;
    background-color: #d8d8db;
    height: 2px;
  }
}
.upload{
  &__wrapper {
    margin-top: 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  &__title {
    margin-bottom: 0;
    margin-right: 5px;
  }
} 
.addNewProduct__btn {
  flex-grow: 0;
  margin: 0 10px;
  border-radius: 50% !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
}
.uploadFile {
  margin-top: 20px;
}
</style>
